/*
 * Definitions over this doc: https://emailregex.com/
 */
export const DEVICE_ID_REGEX = new RegExp(/^[A-Z]{4}\s[A-Z]{4}$/) // eslint-disable-line
export const LocaleStrings = {
  verificationTitle: 'verificationWindow.title',
  verificationContent: 'verificationWindow.content',
  verificationBoxLabel: 'verificationWindow.boxLabel',
  verificationButtonLabel: 'verificationWindow.buttonLabel',
  continueButtonLabel: 'verificationWindow.continueButtonLabel',
  verificationErrorText: 'verificationWindow.errortext',
  verifiedTitle: 'verifiedWindow.title',
  accessDeniedTitle: 'verifiedWindow.accessDeniedTitle',
  deviceName: 'verifiedWindow.deviceName',
  serialNo: 'verifiedWindow.serialNo',
  description: 'verifiedWindow.description',
  alreadyVerifiedDesc: 'verifiedWindow.description2',
  alreadyClaimedDesc: 'verifiedWindow.alreadyClaimedDesc',
  noPermissionDesc: 'verifiedWindow.noPermissionDesc',
  anotherDeviceText: 'verifiedWindow.anotherDeviceText',
  anotherDeviceLink: 'verifiedWindow.anotherDeviceLink',
  FooterTrailingText: 'footer.trailingAreatext',
  FooterLeadingText: 'footer.leadingAreatext',
  header: 'header',
  Logout: 'contextualMenu.label',
  hpPrivacyStatement: 'tos.hpPrivacyStatement',
  and: 'tos.and',
  hpTos: 'tos.hpTos',
  clickVerifyText: 'tos.clickVerifyText'
}
