import React from 'react'
import ManageOnboarding from '../src/components/ManageOnboarding'
import { Stack } from '@jarvis/web-stratus-client'
import { ShellProps } from '../src/types/shell'
import projectNames from '../src/configs/projectNames'
import ToastProvider from '@veneer/core/dist/scripts/toast_container/toast_provider'
import RootProvider from '../src/Root/RootProvider'
import { MfePropsType } from '../src/types/mfeProps'
import { setProps } from '../src/utils/commonMethods'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

type ManageOnboardingProps = {
  country?: string
  language?: string
  stack: Stack
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({
  ...props
}: ManageOnboardingProps & MfePropsType) {
  const { v1, v2 } = window.Shell

  const mfeProps = { ...props, ...v1, ...v2, Stack }

  setProps(mfeProps)

  // You can find the "stack" information by props
  // const stack = props.stack.
  return (
    <RootProvider {...mfeProps}>
      <section id={projectNames.packageJsonName}>
        <ToastProvider>
          <ManageOnboarding {...{ ...props, ...v1, ...v2, Stack }} />
        </ToastProvider>
      </section>
    </RootProvider>
  )
}
