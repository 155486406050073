import { ManageOnboardingProps } from '../components/ManageOnboarding'
import { AssetsProvider } from '@jarvis/web-assets-provider'
import AssetsProviderFactory from '../assets/AssetsProviderFactory'

let localprops: ManageOnboardingProps = {
  language: 'en',
  country: 'US',
  authProvider: null,
  stack: null
}

export const setProps = (props: ManageOnboardingProps) => {
  localprops = props
}

export const getProps = (): ManageOnboardingProps => {
  return localprops
}

export const getText = (subkey: string): string => {
  const props = getProps()
  const assetsProvider: AssetsProvider = AssetsProviderFactory.create(
    props.language,
    props.country
  )
  const assetKey = `shell-onboarding-center.${subkey}`
  return assetsProvider.getText(assetKey)
}
