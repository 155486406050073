/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import Button from '@veneer/core/dist/scripts/button/button'
import { IconCheckCircle, IconXCircle } from '@veneer/core/dist/scripts/icons'
import TextBox from '@veneer/core/dist/scripts/text_box/text_box'
import CardHeader from '../CardHeader'
import { MicroFrontend } from '@jarvis/react-mfe-component'

import {
  Container,
  Card,
  TitleStyle,
  LabelStyle,
  TextboxStyle,
  ButtonStyle,
  SuccessIconStyle,
  BottomTextStyle,
  TextStyle,
  LinkStyle,
  DeviceDetailsBoxStyle,
  DeviceDetailsStyle,
  DeviceItemStyle,
  DeviceDescStyle,
  FooterContainer,
  LanguageStyle,
  CopyrightStyle,
  DeviceItemStyleDescription,
  TosAndVerifyBox,
  TosBoxStyle,
  AcceptButtonStyle,
  ConsentStatementStyle,
  ConsentTextStyle,
  LanguageSelectorStyle
} from '../styles'
import '../styles.scss'
import { DEVICE_ID_REGEX } from '../../utils/constants'
import { setProps, getProps } from '../../utils/commonMethods'
import { DeviceState } from '../../interfaces/deviceInterface'
import { Stack } from '@jarvis/web-stratus-client'
import {
  getActiveUser,
  getVerifyDeviceResp,
  getConfirmedDeviceDetails,
  getParticipantsInfo,
  getDeviceModelDetails,
  validateDevice,
  registerDevice
  // getConsent,
  // getCatalogProgram
} from '../../utils/api'
import Cookies from 'js-cookie'
import useToast from '@veneer/core/dist/scripts/toast_container/use_toast'
import useRootContext from '../../Root/useRootContext'
import { Scrollbar } from '@veneer/core'

export type ManageOnboardingProps = {
  country?: string
  language?: string
  authProvider: any
  stack: Stack
  mock?: boolean
  mockProp?: boolean
}
const initialsDefaultValue = { value: '', loading: true }
const ManageOnboarding = (props: ManageOnboardingProps) => {
  setProps(props)
  const { localization } = useRootContext()
  const { t } = localization.useReactTranslatorHook()
  const { addToast } = useToast()
  const [
    isConfirmedVerification,
    setIsConfirmedVerification
  ] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [errorCode, setErrorCode] = useState<boolean>(false)
  const [deviceForm, setDeviceForm] = useState<DeviceState>({
    deviceId: '',
    hasChanges: false,
    errorMessage: null
  })
  const [userLabel, setUserLabel] = useState(initialsDefaultValue)
  const [deviceModel, setDeviceModel] = useState<string>('')
  const [deviceSerialNumber, setDeviceSerialNumber] = useState<string>('')
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [isConsent, setIsConsent] = useState<boolean>(
    props.mockProp ? true : false
  )
  const [claimedBySameClient, setClaimedBySameClient] = useState<boolean>(false)
  const [alreadyClaimed, setAlreadyClaimed] = useState<boolean>(false)
  const [noPrmission, setNoPrmission] = useState<boolean>(false)
  const [tenantId, setTenantId] = useState<string>('')
  const LanguageSelectorMfe = useCallback((type) => {
    return (
      <MicroFrontend
        type={type}
        component={'@jarvis/react-ecp-language-selector-widget'}
      />
    )
  }, [])
  const callToastOnError = useCallback(
    (errorMessage) => {
      addToast({
        id: 'send-invitation-success',
        type: 'negative',
        text: t(
          'shell-onboarding-center.errorModal.systemError',
          'System Error'
        ),
        subtitle:
          t(
            'shell-onboarding-center.errorModal.errorMessage',
            'An error occurred while processing your request. Try again later. If the problem persists, contact your partner or HP for more information. (Error:'
          ) +
          errorMessage +
          ')'
      })
    },
    [addToast, t]
  )

  const retriveActiveUser = async () => {
    await getActiveUser()
      .then((resp) => {
        const firstLetter = resp?.user?.givenName?.toUpperCase() || ''
        const secondLetter = resp?.user?.familyName?.toUpperCase() || ''
        const userEmail =
          resp?.user?.userName?.substring(0, 2)?.toUpperCase() || ''
        const activeTenantId = resp?.userTenantDetail?.tenantResourceId
        firstLetter !== ''
          ? setUserLabel({
              value: firstLetter + secondLetter,
              loading: false
            })
          : setUserLabel({ value: userEmail, loading: false })
        setTenantId(activeTenantId)
      })
      .catch((e) => {
        callToastOnError(e.message)
      })
  }

  useEffect(() => {
    retriveActiveUser()
  }, [])

  const validateDeviceId = (deviceId: string) => {
    const invalidMessage = DEVICE_ID_REGEX.test(deviceId.toUpperCase())
      ? null
      : t(
          'shell-onboarding-center.verificationWindow.errortext',
          'Invalid code. Check the code on your device, and then try again.'
        )

    let deviceIdnew = deviceId.split(' ').join('')

    deviceIdnew.length > 0
      ? (deviceIdnew = deviceIdnew.match(new RegExp('.{1,4}', 'g')).join(' '))
      : (deviceIdnew = deviceId)

    setDeviceForm({
      deviceId: deviceIdnew.toUpperCase(),
      hasChanges: !errorMessage,
      errorMessage: errorMessage || null
    })
    invalidMessage ? setIsDisabled(true) : setIsDisabled(false)
  }

  // Verify device: user code
  const verifyDevice = async () => {
    setIsConsent(false)
    setIsConfirmedVerification(true)
    setIsDisabled(true)
    await getVerifyDeviceResp(deviceForm.deviceId, false)
      .then(async ({ status, data }) => {
        if (status === 200) {
          setDeviceModel(data?.device_model)
          setDeviceSerialNumber(data?.serial_number)
          setDeviceForm({
            deviceId: deviceForm.deviceId,
            hasChanges: false,
            errorMessage: null
          })
          await getPreRegStatus(
            data?.product_number,
            data?.serial_number,
            data?.device_postcard,
            data?.device_uuid,
            data?.biz_model,
            data?.fingerprint
          )
        }
      })
      .catch(() => {
        setErrorMessage(
          t(
            'shell-onboarding-center.verificationWindow.errortext',
            'Invalid code. Check the code on your device, and then try again.'
          )
        )
        setIsConfirmedVerification(false)
        setIsDisabled(false)
      })
  }

  // Check pre registration status device
  const getPreRegStatus = async (
    productNumber: string,
    deviceSerialNumber: string,
    devicePost: string,
    deviceUUID: string,
    bizModel: string,
    fingerPrint: string
  ) => {
    await getParticipantsInfo(productNumber, deviceSerialNumber)
      .then(async (response) => {
        if (response.data?.companyTenantId === tenantId) {
          await getDeviceModelInfo(
            productNumber,
            devicePost,
            deviceUUID,
            bizModel,
            fingerPrint
          )
        } else {
          response.data?.companyTenantId === ''
            ? setErrorCode(true)
            : setAlreadyClaimed(true)
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            setNoPrmission(true)
          } else {
            setIsVerified(false)
            setIsConfirmedVerification(false)
            setIsDisabled(false)
            callToastOnError(error.message)
          }
        }
      })
  }

  // get device model details
  const getDeviceModelInfo = async (
    productNumber: string,
    devicePost: string,
    deviceUUID: string,
    bizModel: string,
    fingerPrint: string
  ) => {
    await getDeviceModelDetails(productNumber)
      .then(async (resp) => {
        resp.platformIdentifier === 'gen2Yeti' || props.mockProp
          ? await confirmDevice(
              productNumber,
              devicePost,
              deviceUUID,
              bizModel,
              fingerPrint
            )
          : await claimRegisteredDevice(
              productNumber,
              devicePost,
              deviceUUID,
              bizModel,
              fingerPrint
            )
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404
          ) {
            setIsVerified(false)
          } else {
            setIsVerified(false)
            setIsConfirmedVerification(false)
            setIsDisabled(false)
            callToastOnError(error.message)
          }
        }
      })
  }

  // Confirm device
  const confirmDevice = async (
    productNumber: string,
    devicePost: string,
    deviceUUID: string,
    bizModel: string,
    fingerPrint: string
  ) => {
    await getConfirmedDeviceDetails(deviceUUID)
      .then(async () => {
        await getDeviceValidStatus(
          productNumber,
          devicePost,
          deviceUUID,
          bizModel,
          fingerPrint
        )
      })
      .catch((error) => {
        const errorMes = error?.response.data.error
        errorMes === 'authorization_pending'
          ? setTimeout(
              () =>
                confirmDevice(
                  productNumber,
                  devicePost,
                  deviceUUID,
                  bizModel,
                  fingerPrint
                ),
              1000
            )
          : (setIsConfirmedVerification(false),
            setIsDisabled(false),
            callToastOnError(error.message))
      })
  }

  // validate device
  const getDeviceValidStatus = async (
    productNumber: string,
    devicePost: string,
    deviceUUID: string,
    bizModel: string,
    fingerPrint: string
  ) => {
    await validateDevice(tenantId, deviceUUID)
      .then(async (response) => {
        const connectivityState = response.data.deviceStatus.connectivityState
        connectivityState === 'offline'
          ? setTimeout(
              async () =>
                await getDeviceValidStatus(
                  productNumber,
                  devicePost,
                  deviceUUID,
                  bizModel,
                  fingerPrint
                ),
              7000
            )
          : await claimRegisteredDevice(
              productNumber,
              devicePost,
              deviceUUID,
              bizModel,
              fingerPrint
            )
      })
      .catch((error) => {
        callToastOnError(error.message)
        setIsConfirmedVerification(false)
        setIsDisabled(false)
      })
  }

  // Register a device
  const claimRegisteredDevice = async (
    productNumber: string,
    devicePost: string,
    deviceUUID: string,
    bizModel: string,
    fingerPrint: string
  ) => {
    const programId = '71136f2c-4303-48ae-993a-5c7ff11d66c9'
    await registerDevice(
      tenantId,
      devicePost,
      productNumber,
      bizModel,
      deviceUUID,
      fingerPrint,
      programId
    )
      .then((resp) => {
        resp === undefined
          ? setIsVerified(true)
          : resp.data.cloudId
          ? setIsVerified(true)
          : resp.status === 400
          ? setClaimedBySameClient(true)
          : (callToastOnError(resp.statusText),
            setIsConfirmedVerification(false),
            setIsDisabled(false))
      })
      .catch((error) => {
        if (error.response) {
          const errorMes = error?.response.status
          if (errorMes === 400) {
            setClaimedBySameClient(true)
          } else {
            setIsConfirmedVerification(false)
            setIsDisabled(false)
            callToastOnError(error.message)
          }
        } else {
          setIsConfirmedVerification(false)
          setIsDisabled(false)
          callToastOnError(error.message)
        }
      })
  }

  const logoutSession = async () => {
    const baseUrlSessionApi = `${location.origin}/api/session/v1/logout`
    let baseUrlAuthz
    const getStack = getProps().stack
    getStack === 2
      ? (baseUrlAuthz = 'https://authz.stage.api.ws-hp.com/openid/v1/logout')
      : getStack === 3
      ? (baseUrlAuthz = 'https://authz.api.ws-hp.com//openid/v1/logout')
      : (baseUrlAuthz = 'https://pie.authz.wpp.api.hp.com/openid/v1/logout')

    const stratusId = Cookies.get('stratus-id-token')
    Cookies.remove('shellOnboardingFinished')
    Cookies.remove('shellTenantId')

    return new Promise(() => {
      window.location.href = `${baseUrlAuthz}?id_token_hint=${stratusId}&post_logout_redirect_uri=${baseUrlSessionApi}&state=shell`
    })
  }
  const renderTitle = () => {
    return (
      <TitleStyle data-test-id="card-title-container">
        <h4 data-test-id="card-title">
          {isVerified || claimedBySameClient
            ? t('shell-onboarding-center.verifiedWindow.title', 'Registered')
            : alreadyClaimed || noPrmission
            ? t(
                'shell-onboarding-center.verifiedWindow.accessDeniedTitle',
                'Access denied'
              )
            : t(
                'shell-onboarding-center.verificationWindow.title',
                'Verify a device'
              )}
        </h4>
      </TitleStyle>
    )
  }
  const renderTextBox = () => {
    return (
      <>
        <LabelStyle data-test-id="textbox-label">
          {t(
            'shell-onboarding-center.verificationWindow.content',
            'Enter the code displayed on your device.'
          )}
        </LabelStyle>
        <TextboxStyle data-test-id="deviceid-textbox-container">
          <TextBox
            id="label-placeholder"
            label={t(
              'shell-onboarding-center.verificationWindow.boxLabel',
              'Code'
            )}
            onChange={(value) => validateDeviceId(value.toString())}
            value={deviceForm.deviceId}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            maxLength={9}
            className="text-box-style"
            data-test-id="deviceid-textbox"
            disabled={isConfirmedVerification}
          />
        </TextboxStyle>
      </>
    )
  }

  const renderDeviceDetails = () => {
    return (
      <>
        <DeviceDetailsBoxStyle data-test-id="device-details-box">
          <DeviceDetailsStyle data-test-id="device-details-description">
            <DeviceItemStyleDescription>
              {errorCode || claimedBySameClient
                ? t(
                    'shell-onboarding-center.verifiedWindow.description2',
                    'This device has already been registered to HP Command Center.'
                  )
                : alreadyClaimed
                ? t(
                    'shell-onboarding-center.verifiedWindow.alreadyClaimedDesc',
                    'Another reseller or service provider has already claimed this device in HP Command Center. Contact HP if you own this device.'
                  )
                : noPrmission
                ? t(
                    'shell-onboarding-center.verifiedWindow.noPermissionDesc',
                    'You don’t have permission to register a device. Contact your partner or HP for more information.'
                  )
                : t(
                    'shell-onboarding-center.verifiedWindow.description',
                    'This device has now been added to HP Command Center.'
                  )}
            </DeviceItemStyleDescription>
          </DeviceDetailsStyle>
          {(isVerified || claimedBySameClient) && (
            <>
              <DeviceDetailsStyle data-test-id="device-details">
                <DeviceItemStyle data-test-id="device-name-text">
                  {t(
                    'shell-onboarding-center.verifiedWindow.deviceName',
                    'Device Name'
                  )}
                </DeviceItemStyle>
                <DeviceDescStyle data-test-id="device-name">
                  {deviceModel}
                </DeviceDescStyle>
              </DeviceDetailsStyle>
              <DeviceDetailsStyle data-test-id="device-serial-number-details">
                <DeviceItemStyle data-test-id="device-serial-number-text">
                  {t(
                    'shell-onboarding-center.verifiedWindow.serialNo',
                    'Serial Number'
                  )}
                </DeviceItemStyle>
                <DeviceDescStyle data-test-id="device-serial-number">
                  {deviceSerialNumber}
                </DeviceDescStyle>
              </DeviceDetailsStyle>
            </>
          )}
        </DeviceDetailsBoxStyle>
        <SuccessIconStyle data-test-id="success-icon-box">
          {(isVerified || claimedBySameClient) && (
            <IconCheckCircle
              data-test-id="success-icon"
              size={80}
              color="colorGreen5"
            />
          )}
          {(alreadyClaimed || noPrmission) && (
            <IconXCircle
              data-test-id="failure-icon"
              size={80}
              color="colorRed6"
            />
          )}
        </SuccessIconStyle>
      </>
    )
  }
  const consentContent = () => {
    return (
      <>
        <Scrollbar
          data-test-id="consent-scroller"
          customStyle={{
            overflow: 'auto',
            width: 'auto',
            height: '346px',
            margin: '0 40px'
          }}
        >
          <TitleStyle noSidePadding={true} data-test-id="consent-title">
            <h4>
              {t(
                'shell-onboarding-center.verifiedWindow.connectedPrintingServicesTitle',
                'Connected Printing Services'
              )}
            </h4>
          </TitleStyle>
          <DeviceDetailsBoxStyle data-test-id="consent-details-box">
            <DeviceDetailsStyle data-test-id="consent-details-description">
              <DeviceItemStyleDescription>
                {t(
                  'shell-onboarding-center.verifiedWindow.printingServices1',
                  'When your printer is paired with HP services, HP collects required data from the printer to (i) support features, functions, and services under your company’s contract with HP, and (ii) improve the performance and operation of products, solutions, services and support, including warranty support and timely firmware and software updates, and alerts to ensure the continued operation of the device or service.'
                )}
              </DeviceItemStyleDescription>
            </DeviceDetailsStyle>
          </DeviceDetailsBoxStyle>
          <ConsentStatementStyle data-test-id="consent-access-statement">
            <ConsentTextStyle data-test-id="consent-access-text">
              {t(
                'shell-onboarding-center.verifiedWindow.printingServices2',
                'HP does not access the content of any shared or printed files.'
              )}
            </ConsentTextStyle>
          </ConsentStatementStyle>
        </Scrollbar>
      </>
    )
  }
  // Get Consent Details
  const getConsentDetails = async () => {
    setIsConsent(true)
    // const getConsentResp = getConsent(tenantId).catch((e) => {
    //   callToastOnError(e.message),
    //     setDeviceForm({
    //       deviceId: '',
    //       hasChanges: false,
    //       errorMessage: null
    //     })
    // })
    // const getCatalogProgResp = getCatalogProgram('ws-hp.com/wfh').catch((e) => {
    //   callToastOnError(e.message),
    //     setDeviceForm({
    //       deviceId: '',
    //       hasChanges: false,
    //       errorMessage: null
    //     })
    // })
    // const [consentsData, catalogData] = await Promise.all([
    //   getConsentResp,
    //   getCatalogProgResp
    // ])
    // consentsData == null ||
    // catalogData[0].purposeList[0].purposeId != consentsData.purposeId ||
    // consentsData.state != 'OptedIn'
    //   ? (setIsConsent(false),
    //     setConsentText(''),
    //     setIsConfirmedVerification(false))
    //   : (setIsConsent(true),
    //     setConsentText(consentsData.verbiageValue),
    //     setIsConfirmedVerification(true))
  }

  const renderConsentDetails = () => {
    return (
      <>
        {consentContent()}
        <AcceptButtonStyle data-test-id="accept-button-style">
          <ButtonStyle data-test-id="accept-button-box">
            <Button
              onClick={() => verifyDevice()}
              expanded={true}
              disabled={isDisabled}
              data-test-id="confirm-button"
              loading={isConfirmedVerification ? true : false}
            >
              {t(
                'shell-onboarding-center.verificationWindow.continueButtonLabel',
                'Continue'
              )}
            </Button>
          </ButtonStyle>
        </AcceptButtonStyle>
      </>
    )
  }
  const renderTosAndVerifyBox = () => {
    return (
      <TosAndVerifyBox>
        <TosBoxStyle>
          {t(
            'shell-onboarding-center.tos.clickVerifyText',
            'By clicking Verify I agree to '
          )}
          <a href="/hp-commandcenter-terms-of-service" target="_blank">
            {t(
              'shell-onboarding-center.tos.hpTos',
              'HP Command Centers Terms of Service'
            )}
          </a>
          {` `}
          {t('shell-onboarding-center.tos.and', 'and')}
          {` `}
          <a
            href="https://www.hp.com/us-en/privacy/privacy.html?jumpid=in_R11928_/us/en/corp/privacy-central/privacy-statements"
            target="_blank"
            rel="noreferrer"
          >
            {t(
              'shell-onboarding-center.tos.hpPrivacyStatement',
              'HPs Privacy Statement.'
            )}
          </a>
        </TosBoxStyle>
        <ButtonStyle data-test-id="button-box">
          <Button
            onClick={() => getConsentDetails()}
            expanded={true}
            disabled={isDisabled}
            data-test-id="verify-button"
            loading={isConfirmedVerification ? true : false}
          >
            {t(
              'shell-onboarding-center.verificationWindow.buttonLabel',
              'Verify'
            )}
          </Button>
        </ButtonStyle>
      </TosAndVerifyBox>
    )
  }

  const renderAnotherDeviceLink = () => {
    return (
      <BottomTextStyle data-test-id="verify-another-device-box">
        <TextStyle data-test-id="verify-another-device-text">
          {t(
            'shell-onboarding-center.verifiedWindow.anotherDeviceText',
            'Do you have another device?'
          )}
        </TextStyle>
        <LinkStyle
          data-test-id="verify-another-device-link"
          onClick={() => {
            setIsVerified(false)
            setErrorMessage('')
            setDeviceForm({
              deviceId: '',
              hasChanges: false,
              errorMessage: null
            })
            setIsDisabled(true)
            setAlreadyClaimed(false)
            setIsConfirmedVerification(false)
          }}
        >
          {t(
            'shell-onboarding-center.verifiedWindow.anotherDeviceLink',
            'Enter another code'
          )}
        </LinkStyle>
      </BottomTextStyle>
    )
  }
  return (
    <>
      <Container data-test-id="main-container">
        <Card data-test-id="device-verify-card">
          <CardHeader
            userLabel={userLabel.value}
            loading={userLabel.loading}
            clearSessionAndLogout={() => logoutSession()}
          />
          {isConsent && renderConsentDetails()}
          {(!isConsent || props.mockProp) && (
            <div>
              {renderTitle()}
              {!alreadyClaimed &&
                !isVerified &&
                !noPrmission &&
                !claimedBySameClient &&
                renderTextBox()}
              {(alreadyClaimed ||
                noPrmission ||
                isVerified ||
                claimedBySameClient ||
                props.mockProp) &&
                renderDeviceDetails()}
              {!alreadyClaimed &&
                !isVerified &&
                !noPrmission &&
                !claimedBySameClient &&
                renderTosAndVerifyBox()}
              {(isVerified || alreadyClaimed || props.mockProp) &&
                renderAnotherDeviceLink()}
            </div>
          )}
        </Card>
      </Container>
      <FooterContainer data-test-id="footer-container">
        <CopyrightStyle data-test-id="copyright-text">
          {t(
            'shell-onboarding-center.footer.leadingAreatext',
            '© Copyright. All rights reserved.'
          )}
        </CopyrightStyle>
        <LanguageStyle data-test-id="language-link">
          {t(
            'shell-onboarding-center.footer.trailingAreatext',
            'English (United States)'
          )}
        </LanguageStyle>
        <LanguageSelectorStyle>
          <LanguageSelectorMfe />
        </LanguageSelectorStyle>
      </FooterContainer>
    </>
  )
}

export default ManageOnboarding
