import React from 'react'
import Avatar from '@veneer/core/dist/scripts/avatar/avatar'
import ContextualMenu from '@veneer/core/dist/scripts/contextual_menu/contextual_menu'
import { LogoHp } from '@veneer/core/dist/scripts/logos'
import { Header, LogoStyle, AvatarStyle, Heading } from '../styles'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator/progress_indicator'
import useRootContext from '../../Root/useRootContext'

const CardHeader = ({
  userLabel,
  loading,
  clearSessionAndLogout
}: UserLabelProps) => {
  const { localization } = useRootContext()
  const { t } = localization.useReactTranslatorHook()
  return (
    <Header data-test-id="card-header-container">
      <LogoStyle data-test-id="card-logo-container">
        <LogoHp data-test-id="card-logo" size={36} />
      </LogoStyle>
      <Heading data-test-id="card-heading-container">
        <h6 data-test-id="card-heading">
          {t('shell-onboarding-center.header', 'Command Center')}
        </h6>
      </Heading>
      <AvatarStyle data-test-id="card-avatar-container">
        <ContextualMenu
          placement="bottom-end"
          id="contextual_menu_default"
          onClick={() => clearSessionAndLogout()}
          options={[
            {
              value: 1,
              label: t('shell-onboarding-center.contextualMenu.label', 'Logout')
            }
          ]}
          selectedValue={1}
          data-test-id="logout-menu"
        >
          <Avatar
            {...(loading
              ? { icon: <ProgressIndicator data-testid="card-avatar-loader" /> }
              : { label: userLabel })}
            size={48}
          />
        </ContextualMenu>
      </AvatarStyle>
    </Header>
  )
}

export interface UserLabelProps {
  userLabel: string
  loading: boolean
  clearSessionAndLogout?: any
}
export default CardHeader
