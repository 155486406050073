/* eslint-disable camelcase */
import bg_BG from './bg_BG.json'
import cs_CZ from './cs_CZ.json'
import da_DK from './da_DK.json'
import de_DE from './de_DE.json'
import el_GR from './el_GR.json'
import en_AU from './en_AU.json'
import en_IL from './en_IL.json'
import en_NZ from './en_NZ.json'
import en_US from './en_US.json'
import es_ES from './es_ES.json'
import et_EE from './et_EE.json'
import fr_FR from './fr_FR.json'
import hr_HR from './hr_HR.json'
import hu_HU from './hu_HU.json'
import it_IT from './it_IT.json'
import ja_JP from './ja_JP.json'
import ko_KR from './ko_KR.json'
import lt_LT from './lt_LT.json'
import lv_LV from './lv_LV.json'
import nl_NL from './nl_NL.json'
import pl_PL from './pl_PL.json'
import pt_PT from './pt_PT.json'
import ro_RO from './ro_RO.json'
import ru_RU from './ru_RU.json'
import sk_SK from './sk_SK.json'
import sv_SE from './sv_SE.json'
import zh_CN from './zh_CN.json'
import zh_TW from './zh_TW.json'

export default {
  bg_BG,
  cs_CZ,
  da_DK,
  de_DE,
  el_GR,
  en_AU,
  es_ES,
  en_IL,
  en_NZ,
  en_US,
  et_EE,
  fr_FR,
  hr_HR,
  hu_HU,
  it_IT,
  ko_KR,
  lt_LT,
  lv_LV,
  nl_NL,
  pt_PT,
  pl_PL,
  ro_RO,
  ru_RU,
  sk_SK,
  sv_SE,
  zh_CN,
  ja_JP,
  zh_TW
}
