import styled from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import tokens from '@veneer/tokens'

export const Container = styled.section`
  min-height: 96vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${tokens.colorGray0};
  flex-direction: column;
  font-size: ${tokens.fontSize2};
  color: ${tokens.colorGray9};
`
export const Card = styled.div`
  width: 500px;
  height: 586px;
  background-color: ${tokens.colorWhite};
  border-color: ${tokens.colorWhite};
  border-radius: ${tokens.borderRadius4};
  border-style: solid;
  border-width: 1px;
  box-sizing: inherit;
  box-shadow: rgb(33 33 33 / 10%) 0px 4px 16px;
  position: relative;
`
export const Header = styled.div`
  display: flex;
  align-items: stretch;
  padding: 40px 40px 33px 40px;
`
export const LogoStyle = styled.div`
  flex-grow: 0.5;
  padding-top: 5px;
`
export const Heading = styled.div`
  flex-grow: 9;
  padding-top: ${tokens.space2};
`
export const AvatarStyle = styled.div`
  flex-grow: 0.5;
  cursor: pointer;
`
export const TitleStyle = styled.div`
  padding-bottom: 20px;
  padding-right: ${({ noSidePadding }) => (noSidePadding ? '0px' : '40px')};
  padding-left: ${({ noSidePadding }) => (noSidePadding ? '0px' : '40px')};
  h4 {
    font-size: ${tokens.fontSize8};
    line-height: ${tokens.lineHeight7};
  }
`
export const LabelStyle = styled.div`
  font-size: ${tokens.fontSize3};
  padding-bottom: 32px;
  padding-right: 40px;
  padding-left: 40px;
`
export const TextboxStyle = styled.div`
  padding-right: 40px;
  padding-left: 40px;
  /* .caption {
    color: ${tokens.colorRed6};
  } */
`

export const SuccessIconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
  padding-right: 40px;
  padding-left: 40px;
`
export const ButtonStyle = styled.div`
  width: 30%;
  padding-top: 32px;
  display: flex;
  align-self: flex-end;
`
export const LinkStyle = styled.div`
  color: ${tokens.colorHpBlue6};
  font-size: ${tokens.fontSize3};
  line-height: ${tokens.lineHeight3};
  cursor: pointer;
  text-align: right;
  padding-right: 25px;
`
export const BottomTextStyle = styled.div`
  padding-right: 40px;
  padding-left: 40px;
`
export const TextStyle = styled.div`
  font-size: ${tokens.fontSize4};
  line-height: ${tokens.lineHeight4};
  padding-bottom: 14px;
`
export const DeviceDetailsBoxStyle = styled.div``
export const DeviceDetailsStyle = styled.div`
  display: flex;
  align-items: flex-start;
`
export const DeviceItemStyle = styled.div`
  font-size: ${tokens.fontSize3};
  line-height: ${tokens.lineHeight4};
  margin-top: ${tokens.space3};
  width: 25%;
`
export const DeviceDescStyle = styled.div`
  font-size: ${tokens.fontSize3};
  line-height: ${tokens.lineHeight4};
  font-weight: 600;
  margin-top: ${tokens.space3};
  padding-left: ${tokens.space6};
`
export const DeviceItemStyleDescription = styled.div`
  font-size: ${tokens.fontSize3};
  line-height: ${tokens.lineHeight4};
`
export const FooterContainer = styled.section`
  display: flex;
  margin-top: -25px;
  align-items: center;
  background-color: ${tokens.colorGray0};
  color: ${tokens.colorGray9};
  width: 100%;
`
export const CopyrightStyle = styled.div`
  font-size: ${tokens.fontSize1};
  float: left;
  width: 50%;
  padding: 0 24px;
`
export const LanguageStyle = styled.div`
  float: right;
  font-size: ${tokens.fontSize2};
  color: ${tokens.colorHpBlue6};
  text-align: right;
  width: 50%;
`
export const LanguageSelectorStyle = styled.div`
  float: right;
  font-size: ${tokens.fontSize2};
  color: ${tokens.colorHpBlue6};
  text-align: right;
  padding: 0 24px;
  width: 18%;
`
export const TosAndVerifyBox = styled.div`
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: absolute;
  padding: 40px;
`
export const TosBoxStyle = styled.div`
  font-size: 17px;
`
export const TosLinkStyle = styled.span`
  color: ${tokens.colorHpBlue6};
  font-size: ${tokens.fontSize3};
  line-height: ${tokens.lineHeight3};
  cursor: pointer;
`
export const AcceptButtonStyle = styled.div`
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: relative;
  align-items: flex-end;
  padding-right: 40px;
`
export const ConsentStatementStyle = styled.div`
  padding: 20px 0;
`
export const ConsentTextStyle = styled.div`
  color: #404040;
  font-weight: 600;
  font-size: 16px;
`
