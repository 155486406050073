import getDevicesList from '../mock/get-devices-list.json'
// import getUser from '../mock/get-active-user.json'
import getVerified from '../mock/get-verified-details.json'
import { JarvisWebHttpClient } from '@jarvis/web-http'
import {
  UserTenantMgtSvcClient,
  ProgramMgtSvcClient,
  DeviceApiClient,
  AccountMgtSvcClient,
  DeviceAuthGrantClient
} from '@jarvis/web-stratus-client'
import { getProps } from './commonMethods'
import { ActiveUser } from '../interfaces/deviceInterface'

export const getActiveUser = async (): Promise<ActiveUser> => {
  const props = getProps()
  const client = new UserTenantMgtSvcClient(props.stack, props.authProvider)
  const response = await client.getCurrentActiveUserWithTenant()
  return response.data
}

// Verify device code
export const getVerifyDeviceResp = async (
  user_code: string,
  holdDevice: boolean
): Promise<any> => {
  const props = getProps()
  const userCode = user_code.split(' ').join('')
  if (props.mock) {
    if (getDevicesList.user_code === userCode) return getDevicesList
  }
  const client = new DeviceAuthGrantClient(props.stack, props.authProvider)
  const response = await client.verifiedDeviceDetails(userCode, holdDevice)
  return response
}

// Confirm device
export const getConfirmedDeviceDetails = async (
  deviceUUID: string
): Promise<any> => {
  const props = getProps()
  if (props.mock) {
    return getVerified
  }
  const client = new DeviceAuthGrantClient(props.stack, props.authProvider)
  const response = await client.confirmedDeviceDetails(deviceUUID)
  return response
}

// Check if device is Preregistered with current user's tenant
export const getParticipantsInfo = async (
  productNumber: string,
  serialNumber: string
): Promise<any> => {
  const props = getProps()
  const client = new ProgramMgtSvcClient(props.stack, props.authProvider)
  const response = await client.getDeviceParticipantInfo(
    productNumber,
    serialNumber
  )
  return response
}

// get device model details:  platformidentifier
export const getDeviceModelDetails = async (
  modelNumber: string
): Promise<any> => {
  const props = getProps()
  const client = new DeviceApiClient(props.stack, props.authProvider)
  const response = await client.getModelDetails(modelNumber)
  return response.data
}

// Validate a device
export const validateDevice = async (
  accountId: string,
  uuid: string
): Promise<any> => {
  const props = getProps()
  const client = new AccountMgtSvcClient(props.stack, props.authProvider)
  const response = await client.validateDevice(accountId, uuid)
  return response
}

// Register a device: Yolo Avengers
export const registerDevice = async (
  accountId: string,
  claimPostcard: string,
  productNumber: string,
  selectedBizModel: string,
  uuid: string,
  fingerPrint: string,
  programId: string
): Promise<any> => {
  const props = getProps()
  const client = new AccountMgtSvcClient(props.stack, props.authProvider)
  try {
    const response = await client.registerDevice(
      accountId,
      claimPostcard,
      productNumber,
      selectedBizModel,
      uuid,
      fingerPrint,
      programId
    )
    return response
  } catch (error) {
    return error.response
  }
}

// Get Consent details
export const getConsent = async (tenantId: string): Promise<any> => {
  const props = getProps()
  let stackString: string
  props.stack === 0
    ? (stackString = 'dev')
    : props.stack === 1
    ? (stackString = 'pie')
    : props.stack === 2
    ? (stackString = 'stage')
    : props.stack === 3
    ? (stackString = 'pro')
    : (stackString = 'pie') //props.stack === 3 (PROD)

  const jarvisWebHttpProvider = new JarvisWebHttpClient({
    defaultAuthProvider: props.authProvider
  })

  const response = await jarvisWebHttpProvider.get({
    url:
      'https://stratus-' +
      stackString +
      '.tropos-rnd.com/v2/consentmgtsvc/consents',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { tenantId: tenantId }
  })
  return response
}

// Get catalog programs
export const getCatalogProgram = async (name: string): Promise<any> => {
  const props = getProps()
  let stackString: string
  props.stack === 0
    ? (stackString = 'dev')
    : props.stack === 1
    ? (stackString = 'pie')
    : props.stack === 2
    ? (stackString = 'stage')
    : props.stack === 3
    ? (stackString = 'pro')
    : (stackString = 'pie') //props.stack === 3 (PROD)

  const jarvisWebHttpProvider = new JarvisWebHttpClient({
    defaultAuthProvider: props.authProvider
  })

  const response = await jarvisWebHttpProvider.get({
    url:
      'https://stratus-' +
      stackString +
      '.tropos-rnd.com/v2/catalogmgtsvc/programs',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { name: name }
  })
  return response
}
